<template>
  <div>
    <ca-header heading="Fahrzeuge Pool" />
    <div class="container-fluid px-4 pt-4">
      <div class="row mb-4">
        <div class="col-12 col-xl-8">
          <ca-card class="mb-4">
            <template #header>
              <h4>Schnelle Suche</h4>
            </template>
            <template #body>
              <div class="form-row my-3">
                <div class="col-12 col-md-8">
                  <basic-input
                    id="producer"
                    v-model="filter.producer"
                    :margin="false"
                    :horizontal="true"
                    :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                    label="Marke / Modell"
                    name="Marke"
                    placeholder="Marke"
                  />
                </div>
                <div class="col-12 col-md-4">
                  <basic-input
                    id="type"
                    v-model="filter.type"
                    :margin="false"
                    placeholder="Modell"
                    name="Modell"
                  />
                </div>
              </div>
              <div class="form-row my-3">
                <div class="col-12">
                  <basic-input
                    id="producer"
                    v-model="filter.chassi"
                    :margin="false"
                    :horizontal="true"
                    :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                    label="FIN"
                    name="FIN"
                    placeholder="XXXXXXXXXXXXXXXXX"
                    minlength="17"
                    maxlength="17"
                  />
                </div>
              </div>
              <div class="form-row my-3">
                <div class="col-12 col-md-8">
                  <basic-input
                    id="priceFrom"
                    v-model="filter.priceFrom"
                    :margin="false"
                    :horizontal="true"
                    :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                    label="Preis von / bis"
                    name="Preis von"
                    placeholder="Preis von"
                  />
                </div>
                <div class="col-12 col-md-4">
                  <basic-input
                    id="priceTo"
                    v-model="filter.priceTo"
                    :margin="false"
                    placeholder="Preis bis"
                    name="Preis bis"
                  />
                </div>
              </div>
              <div class="form-row my-3">
                <div class="col-12 col-md-8">
                  <basic-datepicker
                    id="registrationDateFrom"
                    v-model="filter.registrationDateFrom"
                    :margin="false"
                    :horizontal="true"
                    :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                    label="Erstzulassung von / bis"
                    name="Erstzulassung von"
                    placeholder="TT.MM.JJJJ"
                  />
                </div>
                <div class="col-12 col-md-4">
                  <basic-datepicker
                    id="registrationDateTo"
                    v-model="filter.registrationDateTo"
                    :margin="false"
                    placeholder="TT.MM.JJJJ"
                    name="Erstzulassung bis"
                  />
                </div>
              </div>
              <div class="form-row my-3">
                <div class="col-12 col-md-8">
                  <basic-input
                    id="kmFrom"
                    v-model="filter.kmFrom"
                    :margin="false"
                    :horizontal="true"
                    :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                    label="km von / bis"
                    name="km von"
                    placeholder="km von"
                  />
                </div>
                <div class="col-12 col-md-4">
                  <basic-input
                    id="kmTo"
                    v-model="filter.kmTo"
                    :margin="false"
                    placeholder="km bis"
                    name="km bis"
                  />
                </div>
              </div>
            </template>
          </ca-card>
          <div class="row">
            <div
              v-for="car in cars"
              :key="car._id"
              class="col-12 col-xl-4 col-xxl-3"
            >
              <div
                v-if="car.carAdditionalInfo"
                class="mb-4"
              >
                <car-card
                  :img-source="getCarImage(car)"
                  :eq-height="true"
                  :car-id="car._id"
                  :car-type="car.carAdditionalInfo.constructionShape"
                  :price="50000"
                  :title="car.carAdditionalInfo.description"
                  :description="getQuickFacts(car)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import CarCard from '@/components/CarInPoolCard'
import CaCard from '@/components/Card'
import formatDate from '../../filters/formatDate.js'
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker'
export default {
  name: 'CarList',
  components: {
    CaHeader,
    CarCard,
    CaCard,
    BasicInput,
    BasicDatepicker
  },
  data: () => ({
    filter: {
      producer: '',
      type: '',
      priceFrom: '',
      priceTo: '',
      registrationDateFrom: '',
      registrationDateTo: '',
      kmFrom: '',
      kmTo: '',
      chassi: ''
    },
    cars: null,
    fields: [
      {
        label: 'Kennzeichen',
        key: 'licensePlate'
      },
      {
        label: 'Erstellt am',
        key: 'createdAt',
        formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
        filter: {
          type: 'DATERANGE'
        }
      },
      {
        label: 'Kraftstoff',
        key: 'fuel',
        filter: {
          type: 'LIST',
          fetchOptions: () => ([
            { value: 'Benzin' },
            { value: 'Diesel' },
            { value: 'Elektro' },
            { value: 'Erdgas' },
            { value: 'LPG' }
          ])
        }
      }
    ],
    statusFilter: 1,
    loading: false
  }),
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    },
    baseQuery () {
      const baseQuery = {
        isInPool: true,
        carAdditionalInfoId: { $exists: true }
      }

      if (this.filter.producer !== '') baseQuery.producer = { $regex: this.filter.producer, $options: 'i' }
      if (this.filter.type !== '') baseQuery.type = { $regex: this.filter.type, $options: 'i' }

      if (this.filter.priceFrom || this.filter.priceTo) baseQuery.price = { }
      if (this.filter.priceFrom) baseQuery.price.$gte = this.filter.priceFrom
      if (this.filter.priceTo) baseQuery.price.$lte = this.filter.priceTo

      if (this.filter.registrationDateFrom || this.filter.registrationDateTo) baseQuery.registrationDate = { }
      if (this.filter.registrationDateFrom) baseQuery.registrationDate.$gte = this.filter.registrationDateFrom
      if (this.filter.chassi) baseQuery.chassi = this.filter.chassi
      if (this.filter.registrationDateTo) baseQuery.registrationDate.$lte = this.filter.registrationDateTo

      if (this.filter.kmFrom || this.filter.kmTo) baseQuery.km = { }
      if (this.filter.kmFrom) baseQuery.km.$gte = this.filter.kmFrom
      if (this.filter.kmTo) baseQuery.km.$lte = this.filter.kmTo
      return baseQuery
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    },
    filter: {
      deep: true,
      handler: 'fetchData'
    }
  },
  methods: {
    getCarImage (car) {
      if (car.carAdditionalInfo.images.length !== 0) return car.carAdditionalInfo.images[0].downloadUrl
    },
    async fetchData () {
      try {
        this.loading = true
        this.cars = (await this.$store.dispatch('car/find', {
          query: this.baseQuery
        })).data
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    getQuickFacts (car) {
      const carAdditionalInfo = car.carAdditionalInfo
      let quickFacts = ''
      let enginePowerPS = ''
      let enginePowerKW = ''
      if (carAdditionalInfo) {
        enginePowerKW = `${car.kw}kW`
        enginePowerPS = `${car.kw * 0.73}PS`

        quickFacts = `EZ ${formatDate(car.registrationDate, 'MM/YYYY')}, ${car.km} km, ${car.fuel}, ${enginePowerKW} (${enginePowerPS}), ca. ${carAdditionalInfo.fuelConsumptionCombined}l/100km (komb.), ca. ${carAdditionalInfo.co2EmissionCombined}g. CO2/km (komb.)`
      }

      return quickFacts
    },
    getStatusClass (statusCode) {
      let statusClass = ''
      switch (statusCode) {
      case 0: statusClass = 'badge-default'; break
      case 1: statusClass = 'badge-success'; break
      case 2:
      case 3:
      case 4: statusClass = 'badge-warning'; break
      }
      return statusClass
    },
    setStatusFilter (statusCode) {
      this.statusFilter = statusCode
    }
  }
}
</script>

<style scoped lang="scss">

</style>
