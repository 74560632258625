export default function (value, digits = 2) {
  if (typeof value !== 'number') {
    return value
  }
  var formatter = new Intl.NumberFormat('de', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: digits
  })
  return formatter.format(value)
}
