<template>
  <router-link :to="{ name: 'CarPoolDetail', params: { carId } }">
    <div
      class="card"
      :class="{'h-100': eqHeight, 'mb-3': !eqHeight}"
    >
      <div class="img-price-wrapper card-img-top header-img">
        <img
          v-if="imgSource"
          :src="imgSource"
          class="card-img-top header-img"
        >
        <div
          v-if="!imgSource"
          class="icon-wrapper"
        >
          <div class="d-flex justify-content-center align-items-center h-100">
            <img
              v-if="carType === 'Cabrio'"
              src="@/assets/img/CarTypes/01-Cabrio-Roadster.svg"
            >
            <img
              v-if="carType === 'SmallCar'"
              src="@/assets/img/CarTypes/02-Kleinwagen.svg"
            >
            <img
              v-if="carType === 'EstateCar'"
              src="@/assets/img/CarTypes/03-Kombi.svg"
            >
            <img
              v-if="carType === 'Limousine'"
              src="@/assets/img/CarTypes/04-Limousine.svg"
            >
            <img
              v-if="carType === 'SportsCar'"
              src="@/assets/img/CarTypes/05-Sportwagen-Coupé.svg"
            >
            <img
              v-if="carType === 'OffRoad'"
              src="@/assets/img/CarTypes/06-SUV-Geländewagen-Pickup.svg"
            >
            <img
              v-if="carType === 'Van'"
              src="@/assets/img/CarTypes/07-Van-Minibus.svg"
            >
            <img
              v-if="carType === 'OtherCar'"
              src="@/assets/img/CarTypes/08-otherCar.svg"
            >
          </div>
        </div>
        <div class="price shadow-sm">
          {{ price | formatPrice }}
        </div>
      </div>
      <div class="card-body">
        <h4 class="text-gray-5">
          {{ title }}
        </h4>
        <p class="text-gray-4 mb-0">
          {{ description }}
        </p>
      </div>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.price{
  font-size: 1.2em;
  color: $primary;
  font-weight: bold;
  position: absolute;
  bottom: 10%;
  right: 0;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px 0px 0px 5px;
}

.icon-wrapper{
  background-color: $secondary;
  height: 100%;
}

.img-price-wrapper{
  position: relative;
}

.header-img {
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  height: 250px;
}

</style>

<script>
import formatPrice from '../filters/formatPrice.js'
export default {
  name: 'CarInPoolCard',
  components: {
  },
  filters: {
    formatPrice
  },
  props: {
    imgSource: {
      default: '',
      type: String
    },
    price: {
      default: undefined,
      type: Number
    },
    description: {
      default: 'missing description',
      type: String
    },
    title: {
      default: 'missing title',
      type: String
    },
    carId: {
      default: '',
      type: String
    },
    carType: {
      default: '',
      type: String
    },
    eqHeight: {
      default: false,
      type: Boolean
    }
  }
}
</script>
